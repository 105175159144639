import { defineStore } from 'pinia';
import {
  checkIn,
  claimReferralReward,
  fetchCompletedTasks,
  fetchReferralCode,
  fetchReferrals,
  fetchUser,
  registerUser,
} from '@/utils/api';
import { REFERRALS_PER_PAGE } from '@/const';
import type { Referral, User } from '@/types/api';
import type { TaskId, Locations } from '@/types/tasks';

export default defineStore('root', {
  state: () => ({
    env: 'PROD' as 'PROD' | 'STAGE' | 'DEV',
    baseApiUrl: null as string | null,
    tgBotUrl: null as string | null,

    telegramId: null as number | null,
    checkString: null as string | null,
    hash: null as string | null,

    user: null as User | null,
    referralCode: null as string | null,
    referrals: [] as Referral[],
    isFetchingReferrals: false,
    shouldResetReferrals: false,

    httpAuth: null as { username: string; password: string } | null,

    locations: [] as Locations,
    completedTasks: null as { task_id: TaskId; choice: number }[] | null,

    loading: true,
  }),
  getters: {
    isDev: state => state.env === 'DEV',
    isProd: state => state.env === 'PROD',
    getTaskById: state => (id: TaskId) => {
      return state.locations
        .map(location => location.tasks)
        .flat(1)
        .find(task => task.id === id);
    },
    getLocationByTaskId: state => (id: TaskId) => {
      return state.locations.find(location =>
        location.tasks.some(task => task.id === id),
      );
    },
  },
  actions: {
    async fetchUser() {
      const responseUser = await fetchUser();
      this.user = responseUser.value;
    },
    async registerUser(referralCode: string) {
      const response = await registerUser({ referral_code: referralCode });
      this.shouldResetReferrals =
        !!this.user &&
        this.user?.referrals_count !== response.value.referrals_count;
      this.user = response.value;
    },
    async fetchReferralCode() {
      const response = await fetchReferralCode();
      this.referralCode = response.value;
    },
    async fetchReferrals() {
      try {
        this.isFetchingReferrals = true;
        const reset = this.shouldResetReferrals;
        const offset = reset ? 0 : this.referrals.length;
        const response = await fetchReferrals(offset, REFERRALS_PER_PAGE);
        this.referrals = reset
          ? response.value
          : [...this.referrals, ...response.value];
      } finally {
        this.isFetchingReferrals = false;
      }
    },
    async fetchReferralInfo() {
      await Promise.all([this.fetchReferralCode(), this.fetchReferrals()]);
    },
    async fetchCompletedTasks() {
      if (!this.user) {
        return;
      }
      const completedTasks = await fetchCompletedTasks();
      this.completedTasks = completedTasks.value;
    },
    async checkIn() {
      const response = await checkIn();
      this.user = response.value;
    },
    resetStreak() {
      if (!this.user) {
        return;
      }
      this.user.check_in_streak = 0;
    },
    async claimReferralReward() {
      const response = await claimReferralReward();
      this.user = response.value;
    },
  },
});
