<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import CoinAnimation from '@/components/CoinAnimation.vue';
import DoubleCoin from '@/components/DoubleCoin.vue';
import MagicTap from '@/components/MagicTap.vue';
import { TAP_INTERVAL } from '@/const';
import { useHapticFeedback } from '@/directives/haptic';
import useStore from '@/store';
import { tap } from '@/utils/api';

const store = useStore();

const tapAnimation = ref<InstanceType<typeof MagicTap> | null>(null);
const coinAnimation = ref<InstanceType<typeof CoinAnimation> | null>(null);

const nextTapIn = ref(Infinity);
const infoText =
  'Collect points to create Samurai \n and start your SORATOPIA journey.';
let nextTapInterval: number | null = null;

const user = computed(() => store.user);

const tapText = computed(() => {
  return nextTapIn.value > 0
    ? `Next time to pray in ${nextTapInFormatted.value}`
    : 'Tap to pray';
});

const nextTapInFormatted = computed(() => {
  const h = Math.floor(nextTapIn.value / 3600);
  const m = Math.floor((nextTapIn.value % 3600) / 60);
  const s = nextTapIn.value % 60;
  return `${h}h ${m}m ${s}s`;
});

const calculateNextTapIn = () => {
  if (!user.value) return;
  const lastTap = new Date(user.value.last_tap_timestamp + 'Z');
  const lastTapTimestamp = Math.floor(lastTap.getTime() / 1000);
  const now = Math.floor(Date.now() / 1000);
  nextTapIn.value = lastTapTimestamp + TAP_INTERVAL - now;
};

const tapAction = async () => {
  if (nextTapIn.value > 0 || !user.value) return;
  nextTapIn.value = TAP_INTERVAL;

  useHapticFeedback('success');

  user.value.last_tap_timestamp = (await tap()).value;

  tapAnimation.value?.play();

  await store.fetchUser();

  setTimeout(() => {
    coinAnimation.value?.play();
  }, 300);
};

onMounted(() => {
  calculateNextTapIn();
  nextTapInterval = setInterval(() => {
    calculateNextTapIn();
  }, 1_000);
});

onBeforeUnmount(() => {
  if (nextTapInterval !== null) {
    clearInterval(nextTapInterval);
  }
});
</script>

<template>
  <div class="home">
    <div class="background">
      <picture>
        <source srcset="img/background.avif" type="image/avif" />
        <source srcset="img/background.png" type="image/png" />
        <img src="img/background.png" alt="Background" />
      </picture>
    </div>
    <div class="animation-layer">
      <MagicTap ref="tapAnimation" />
      <CoinAnimation ref="coinAnimation" />
    </div>
    <div class="coin">
      <picture>
        <source srcset="img/coin.avif" type="image/avif" />
        <source srcset="img/coin.png" type="image/png" />
        <img src="img/coin.png" alt="Coin" />
      </picture>
      <span>{{ user?.score ?? '' }}</span>
    </div>
    <div class="info">{{ infoText }}</div>
    <div class="double-rewards">
      <DoubleCoin />
      <span class="double-rewards__text">
        2x rewards till SORATOPIA full launch
      </span>
    </div>
    <picture>
      <source srcset="img/temple.avif" type="image/avif" />
      <source srcset="img/temple.png" type="image/png" />
      <img
        class="temple"
        src="img/temple.png"
        alt="Temple"
        @click="tapAction"
      />
    </picture>
    <div
      v-if="nextTapIn !== Infinity"
      class="tap-tooltip"
      :class="{ 'tap-tooltip--pending': nextTapIn > 0 }"
      @click="tapAction"
    >
      {{ tapText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background {
  position: absolute;
  height: 100vh;
  overflow: hidden;

  img {
    width: 100vw;
    opacity: 0.1;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;

    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 0%,
      rgba(169, 169, 169, 0.2) 41.3%,
      rgba(115, 115, 115, 0) 91.27%
    );
  }
}

.animation-layer {
  position: absolute;
  width: 100vw;
  top: -10vw;
  height: calc(100vh + 10vw);
  will-change: transform;
  overflow: hidden;
  pointer-events: none;

  canvas {
    position: absolute;
    width: 100vw;
    top: 0;
  }
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.coin {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 7vw;
  font-weight: 600;
  margin-top: 20vw;

  img {
    width: 10vw;
    margin-right: 3vw;
  }
}

.info {
  position: relative;
  margin-top: 2vw;
  color: #8b8b8b;
  font-size: 4vw;
  white-space: pre;
  text-align: center;
}

.temple {
  position: relative;
  width: 65vw;
  margin-top: 2vw;
  z-index: 1;
}

.tap-tooltip {
  position: relative;
  margin-top: 5vw;
  padding: 2vw 5vw;
  border-radius: 100vw;
  background: #e3232c;
  color: white;
  font-weight: 500;
  box-shadow: 0px 4.67191px 23.3596px rgba(227, 35, 44, 0.25);

  &--pending {
    background: #5e5e5e;
    box-shadow: 0px 4.67191px 23.3596px rgba(126, 126, 126, 0.25);
  }
}
.double-rewards {
  position: absolute;
  display: flex;
  font-size: 4vw;
  background-color: #302e2c;
  padding: 1vw 3vw;
  width: 100vw;
  align-items: center;
  justify-content: center;
  &__text {
    margin-left: 1vw;
  }
}
</style>
