<template>
  <div class="tasks">
    <TasksMap v-if="completedTasks && listType === TasksListType.Map" />
    <TasksList v-if="completedTasks && listType === TasksListType.List" />
    <TasksMenu
      :active="listType"
      @input="(type: TasksListType) => (listType = type)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import TasksMap from '@/components/TasksMap.vue';
import TasksList from '@/components/TasksList.vue';
import TasksMenu from '@/components/TasksMenu.vue';
import { TasksListType } from '@/types/tasks';
import useStore from '@/store';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

const listType = computed(() => {
  const type = route.query.type as TasksListType;
  return type || TasksListType.Map;
});

const user = computed(() => store.user);
const completedTasks = computed(() => store.completedTasks);

watch(
  user,
  newUser => {
    if (newUser) {
      store.fetchCompletedTasks();
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.tasks {
  position: relative;
  width: 100vw;
  overflow: hidden;
}
</style>
